import { DynamicPackageResult, SearchSelection } from '@AuroraTypes';
import { usePathTemplate } from '@Core/usePathTemplate';
import { usePandaPageUrl } from '@Pages/search-results/usePandaPageUrl';
import { useSearchSelectionStore } from '@Stores/StoreContext';

interface HolidayCollectionPandaUrlProps {
  masterId: string;
  offer?: DynamicPackageResult;
  offerSearchSelection?: SearchSelection;
}

export const useHolidayCollectionPandaUrl = ({
  masterId,
  offer,
  offerSearchSelection,
}: HolidayCollectionPandaUrlProps): string => {
  const { getPandaUrlForOffer, getPandaUrlParams } = usePandaPageUrl();
  const pandaPath = usePathTemplate('panda');
  const userSearchSelection = useSearchSelectionStore(
    ({ rooms, departureAirports, nights, date }) => ({
      rooms,
      departureAirports,
      nights,
      date,
    }),
  );

  // Respect the search selection connected to the offer to ensure the card clicks through to
  // the same offer on panda, else use the current selection state so that things like recent
  // hotels respect what user was thinking
  if (offer && offerSearchSelection) {
    return getPandaUrlForOffer(offer, offerSearchSelection);
  }

  return `${pandaPath}?${getPandaUrlParams(masterId, userSearchSelection)}`;
};
